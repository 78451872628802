import React, { useState, useEffect } from "react";
import "./Contact.css";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import { Link } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
const Contact = () => {
  // タイトル
  useDocumentTitle("お問い合わせ");
  const [formData, setFormData] = useState({
    companyName: "",
    name: "",
    email: "",
    message: "",
    inquiryType: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [privacyPolicyAccepted, setPrivacyPolicyAccepted] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [recaptchaToken, setRecaptchaToken] = useState(null);
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === "checkbox") {
      setPrivacyPolicyAccepted(checked);
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  // 入力必須項目のチェック
  const validateForm = () => {
    const { name, email, message, inquiryType } = formData;
    if (
      name &&
      email &&
      message &&
      inquiryType &&
      privacyPolicyAccepted &&
      recaptchaToken
    ) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    setIsFormValid(validateForm());
  }, [formData, privacyPolicyAccepted, recaptchaToken]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    // プライバシーポリシー未同意の場合
    if (!privacyPolicyAccepted) {
      setAlertMessage("プライバシーポリシーへの同意が必要です。");
      setAlertType("error");
      setShowAlert(true);
      return;
    }
    if (!recaptchaToken) {
      setAlertMessage("reCAPTCHAの認証が必要です。");
      setAlertType("error");
      setShowAlert(true);
      return;
    }
    setIsSubmitting(true);

    // Lambda呼び出し
    try {
      const response = await fetch(process.env.REACT_APP_LAMBDA_API_URL || "", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...formData,
          recaptchaToken: recaptchaToken,
        }),
      });

      if (response.ok) {
        setAlertMessage("お問い合わせありがとうございます。");
        setAlertType("success");
      } else {
        setAlertMessage("送信に失敗しました。後ほど再度お試しください。");
        setAlertType("error");
      }
    } catch (error) {
      console.error("Error:", error);
      setAlertMessage("送信に失敗しました。後ほど再度お試しください。");
      setAlertType("error");
    } finally {
      console.log("Form Data before showing alert:", formData);
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
        setFormData({
          companyName: "",
          name: "",
          email: "",
          message: "",
          inquiryType: "",
        });
      }, 10000); //10秒後非表示
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    if (showAlert) {
      console.log("Popup Form Data:", formData);
    }
  }, [showAlert]);

  const formatMessage = (message) => {
    return message ? message.replace(/\n/g, "<br/>") : "N/A";
  };
  const handleRecaptchaChange = (token) => {
    setRecaptchaToken(token);
  };
  return (
    <div className="contact-page">
      <h2 className="contact-title">お問い合わせ</h2>
      <div className="contact-content">
        <div className="contact-details">
          <p>
            ※
            Emailにつきましては24時間受け付けておりますが、当社からの返信は当社営業時間内とさせていただきます。
          </p>
          <p>
            <i className="fas fa-phone"></i>　電話番号　　 ：052-684-5742{" "}
          </p>
          <p>
            <i className="fas fa-envelope"></i> メールアドレス：
            <a href="mailto:itplus@i-t-plus.co.jp">itplus@i-t-plus.co.jp</a>
          </p>
          <p>
            <small>
              (営業時間:
              午前9時から午後6時)（土曜・日曜・祝日・年末年始（12/30~1/3）を除く）
            </small>
          </p>
        </div>
        <div className="contact-form">
          <p>
            <strong>フォームによるお問い合わせ</strong>
          </p>
          <form onSubmit={handleSubmit}>
            <label htmlFor="companyName">会社名・団体名</label>
            <input
              type="text"
              id="companyName"
              name="companyName"
              value={formData.companyName}
              onChange={handleChange}
              placeholder="Company or Organization Name"
            />

            <label htmlFor="name">
              お名前 <span className="required">必須</span>
            </label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
              placeholder="Name"
            />

            <label htmlFor="email">
              メールアドレス <span className="required">必須</span>
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
              placeholder="Email"
            />

            <label htmlFor="inquiryType">
              お問い合わせの種類 <span className="required">必須</span>
            </label>
            <select
              id="inquiryType"
              name="inquiryType"
              value={formData.inquiryType}
              onChange={handleChange}
              required
            >
              <option value="" disabled>
                ※選択してください
              </option>
              <option value="企業情報に関するお問い合わせ">
                企業情報に関するお問い合わせ
              </option>
              <option value="サービス内容のお問い合わせ">
                サービス内容のお問い合わせ
              </option>
              <option value="新規ご発注、お見積依頼、開発案件のご相談">
                新規ご発注、お見積依頼、開発案件のご相談
              </option>
              <option value="ご発注ではない弊社への取引依頼やご提案">
                ご発注ではない弊社への取引依頼やご提案
              </option>
              <option value="採用に関するお問い合わせ">
                採用に関するお問い合わせ
              </option>
              <option value="その他のお問い合わせ">その他のお問い合わせ</option>
            </select>

            <label htmlFor="message">
              お問い合わせ詳細 <span className="required">必須</span>
            </label>
            <textarea
              id="message"
              name="message"
              value={formData.message}
              onChange={handleChange}
              required
              placeholder="Message"
            ></textarea>
            <div className="contact-privacy-policy">
              <label htmlFor="privacyPolicy">
                弊社のプライバシーポリシーをご確認の上、ご同意いただきましたら以下にチェックを入れて送信してください。
                <br />
                <input
                  type="checkbox"
                  id="privacyPolicy"
                  name="privacyPolicy"
                  checked={privacyPolicyAccepted}
                  onChange={handleChange}
                  required
                />
                <Link to="/privacy-policy">プライバシーポリシーへの同意*</Link>
              </label>
            </div>
            <div className="recaptcha-container">
              <ReCAPTCHA
                sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY || ""}
                onChange={handleRecaptchaChange}
              />
            </div>

            <button type="submit" disabled={isSubmitting || !isFormValid}>
              {isSubmitting ? "送信中..." : "送信"}
            </button>
          </form>
        </div>
      </div>

      {showAlert && (
        <div className={`custom-alert ${alertType}`}>
          <h2>
            {alertType === "success"
              ? "お問い合わせありがとうございます。"
              : "エラー"}
          </h2>
          <div className="alert-content">
            {alertType === "success" ? (
              <>
                <div>以下の内容でお問い合わせを受け付けました。</div>
                <div>
                  <strong>会社名・団体名:</strong>{" "}
                  <span>{formData.companyName || "N/A"}</span>
                </div>
                <div>
                  <strong>お名前:</strong> <span>{formData.name || "N/A"}</span>
                </div>
                <div>
                  <strong>メールアドレス:</strong>{" "}
                  <span>{formData.email || "N/A"}</span>
                </div>
                <div>
                  <strong>お問い合わせの種類:</strong>{" "}
                  <span>{formData.inquiryType || "N/A"}</span>
                </div>
                <div>
                  <strong>お問い合わせ詳細:</strong>{" "}
                  <span
                    dangerouslySetInnerHTML={{
                      __html: formatMessage(formData.message),
                    }}
                  ></span>
                </div>
              </>
            ) : (
              <div>{alertMessage}</div>
            )}
            <button onClick={() => setShowAlert(false)}>閉じる</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Contact;
